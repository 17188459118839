import classNames from 'classnames';
import React, { ReactNode } from 'react';
import classes from './CommentsGroup.module.scss';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  highlight?: boolean;
  children: ReactNode;
}

const CommentsGroup = ({ highlight = false, className, children, ...divProps }: Props) => {
  return (
    <div
      className={classNames(classes.CommentsGroup, highlight && classes.CommentsGroup__highlight, className)}
      {...divProps}
    >
      {children}
    </div>
  );
};

export default CommentsGroup;
