import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React from 'react';
import ClampLines from '@components/utilities/ClampLines/ClampLines';
import Heading from '@components/utilities/Heading/Heading';
import ElaboratedAnswerSvg from '@icons/elaborated-answer.svg';
import MinusSvg from '@icons/minus-brand-primary.svg';
import PlusSvg from '@icons/plus-brand-primary.svg';
import ReplySvg from '@icons/reply.svg';
import classes from './Comment.module.scss';

export type CommentVariant = 'elaborated' | 'minus' | 'plus' | 'reply';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  text: string;
  title?: string;
  variant: CommentVariant;
}

const CommentIcon = ({ icon }: { icon: CommentVariant }) => {
  switch (icon) {
    case 'elaborated':
      return <ElaboratedAnswerSvg />;
    case 'minus':
      return <MinusSvg />;
    case 'plus':
      return <PlusSvg />;
    case 'reply':
      return <ReplySvg />;
    default:
      return null;
  }
};

const Comment = ({ className, text, title, variant, ...divProps }: Props) => {
  const { t } = useTranslation(['profile']);

  return (
    <div
      {...divProps}
      className={classNames(classes.Comment, className, variant === 'reply' && classes.CommentWithBackground)}
    >
      <CommentIcon key="icon" icon={variant} />
      <div key="comment">
        {title && <Heading>{title}</Heading>}

        {variant === 'reply' && <strong>{t('EMPLOYER_REACTION')}:</strong>}

        <ClampLines lines={6} showButtons>
          <div className={classes.Comment__text}>{text}</div>
        </ClampLines>
      </div>
    </div>
  );
};

export default Comment;
