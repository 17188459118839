import classNames from 'classnames';
import React from 'react';
import classes from './Heading.module.scss';

interface Props extends React.ComponentPropsWithRef<'div'> {
  icon?: JSX.Element;
  withBackground?: boolean;
}

const Heading = ({ children, className, icon, withBackground, ...divProps }: Props) => (
  <div
    className={classNames(classes.Heading, (withBackground || !!icon) && classes.HeadingWithBackground, className)}
    {...divProps}
  >
    {icon ? (
      <>
        {icon}
        <span>{children}</span>
      </>
    ) : (
      children
    )}
  </div>
);

export default Heading;
