import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useRef, useState } from 'react';
import classes from './ClampLines.module.scss';

interface ClampProps extends React.ComponentPropsWithoutRef<'div'> {
  lines: number;
  showButtons?: boolean;
  withTooltip?: boolean;
}

const ClampLines = ({
  lines,
  showButtons = false,
  withTooltip = false,
  children,
  className,
  ...divProps
}: ClampProps) => {
  const [expanded, setExpanded] = useState(false);
  const [isClamped, setIsClamped] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('profile');

  const updateClamping = () => {
    if (contentRef.current) {
      const lineHeight = parseFloat(getComputedStyle(contentRef.current).lineHeight);
      const maxHeight = lineHeight * lines;
      if (showButtons) {
        setIsClamped(contentRef.current.scrollHeight > maxHeight);
      }
    }
  };

  useEffect(() => {
    updateClamping();
    window.addEventListener('resize', updateClamping);

    return () => {
      window.removeEventListener('resize', updateClamping);
    };
  }, [children]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div {...divProps} className={className}>
      <div
        ref={contentRef}
        className={classNames({ [classes.ClampLines__content]: !expanded })}
        style={{ WebkitLineClamp: expanded ? 'none' : lines, lineClamp: expanded ? 'none' : lines }}
        title={withTooltip ? contentRef.current?.textContent ?? '' : undefined}
      >
        {children}
      </div>
      {showButtons && isClamped && (
        <button type="button" onClick={toggleExpanded} className={classes.ClampLines__toggle}>
          {expanded ? t('COMMENT_SHOW_LESS') : t('COMMENT_SHOW_MORE')}
        </button>
      )}
    </div>
  );
};

export default ClampLines;
