import classNames from 'classnames';
import React from 'react';
import classes from './List.module.scss';

interface Props extends React.ComponentPropsWithoutRef<'ul'> {
  listIconType: 'checkmark-round' | 'cross-round' | 'tick';
}

const List = ({ children, listIconType, ...ulProps }: Props) => {
  const iconClassname = classes[`List__checkmark--${listIconType}`];

  return (
    <ul className={classNames(classes.List__checkmark, iconClassname)} {...ulProps}>
      {children}
    </ul>
  );
};

export default List;
