import { useQuery } from '@apollo/client';
import { Container } from '@lmc-eu/spirit-web-react';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { useContext } from 'react';
import Comment from '@components/utilities/Comment/Comment';
import CommentsGroup from '@components/utilities/CommentsGroup/CommentsGroup';
import HtmlParser from '@components/utilities/HtmlParser';
import NextButtonLink from '@components/utilities/NextButtonLink/NextButtonLink';
import SuitableCard from '@components/utilities/SuitableCard/SuitableCard';
import { useFormatNumber } from '@hooks/useNumberFormat';
import { getSiteName } from '@libs/getSiteName';
import { useGenerateRoute } from '@libs/localizedRoute';
import LogoJobs from 'public/images/introduction/jobs_rounded_v2.svg';
import LogoPrace from 'public/images/introduction/prace_rounded_v2.svg';
import classes from './Introduction.module.scss';
import {
  ContactsForDomainQuery,
  ContactsForDomainQueryVariables,
  HomepageStatisticsQuery,
  HomepageStatisticsQueryVariables,
  SuitableForTileForIntroductionPageQuery,
  SuitableForTileForIntroductionPageQueryVariables,
} from '../../graphql/generated/types/graphqlTypes';
import { CONTACTS_FOR_DOMAIN } from '../../graphql/queries/domainSpecificData';
import { HOMEPAGE_STATISTICS } from '../../graphql/queries/homepage';
import { INTRODUCTION_SUITABLE_FOR_TILE } from '../../graphql/queries/profile';
import { RequestContext } from '../../helpers/requestContext';
import List from '../utilities/List/List';

const siteName = getSiteName();

const Introduction = () => {
  const { t } = useTranslation(['front', 'url', 'questionnaire']);
  const generateRoute = useGenerateRoute();
  const { formatNumber } = useFormatNumber();

  const { data: contactsForDomain } = useQuery<ContactsForDomainQuery, ContactsForDomainQueryVariables>(
    CONTACTS_FOR_DOMAIN,
  );
  const { data: homepageStatistics } = useQuery<HomepageStatisticsQuery, HomepageStatisticsQueryVariables>(
    HOMEPAGE_STATISTICS,
  );

  const { data: suitableForTileData } = useQuery<
    SuitableForTileForIntroductionPageQuery,
    SuitableForTileForIntroductionPageQueryVariables
  >(INTRODUCTION_SUITABLE_FOR_TILE);
  const customerCareEmail = contactsForDomain?.contactsForDomain?.customerCareEmail;
  const codexUrl = t('CODEX_URL', { ns: 'url' });
  const requestContextData = useContext(RequestContext);

  return (
    <main>
      <Container UNSAFE_className={classNames(classes.Introduction__container, 'typography-body-medium-text-regular')}>
        <h1 className="typography-heading-large-text">{t('INTRODUCTION_TITLE')}</h1>
        <p>
          <HtmlParser
            htmlString={t('INTRODUCTION_PEREX', { homepageUrl: generateRoute('homepage'), siteName })}
            as={'span'}
          />
        </p>
        <p>
          <HtmlParser htmlString={t('INTRODUCTION_FIRST_PARAGRAPH')} as={'span'} />
        </p>
        <p>
          <HtmlParser htmlString={t('INTRODUCTION_SECOND_PARAGRAPH')} as={'span'} />
        </p>
        {suitableForTileData?.suitableForTileForIntroductionPage && (
          <div>
            <SuitableCard
              imageUrl={`https://${requestContextData?.hostname}/${suitableForTileData.suitableForTileForIntroductionPage.imageUrl}`}
              title={t(suitableForTileData.suitableForTileForIntroductionPage.label, { ns: 'homepageSuitableFor' })}
              content={t(suitableForTileData.suitableForTileForIntroductionPage.label, {
                ns: 'suitableForExplanation',
              })}
              suitableProfilesCount={suitableForTileData.suitableForTileForIntroductionPage.countOfResults}
              link={suitableForTileData.suitableForTileForIntroductionPage.companyListLink}
            />
          </div>
        )}

        <h2 className="typography-heading-medium-text mt-1100">{t('INTRODUCTION_FIRST_SUBTITLE')}</h2>
        <p className="typography-body-medium-text-bold">
          <HtmlParser htmlString={t('INTRODUCTION_THIRD_PARAGRAPH')} as={'span'} />
        </p>
        <p>
          <HtmlParser
            htmlString={t('INTRODUCTION_FOURTH_PARAGRAPH', { homepageUrl: generateRoute('homepage'), siteName })}
            as={'span'}
          />
        </p>
        <p>
          <HtmlParser htmlString={t('INTRODUCTION_FIFTH_PARAGRAPH', { codexUrl })} as={'span'} />
        </p>
        <p className="typography-body-medium-text-bold">
          <HtmlParser htmlString={t('INTRODUCTION_SIXTH_PARAGRAPH')} as={'span'} />
        </p>
        <p>
          <HtmlParser
            htmlString={t('INTRODUCTION_SEVENTH_PARAGRAPH', { contactEmail: customerCareEmail })}
            as={'span'}
          />
        </p>
        <p>
          <HtmlParser
            htmlString={t('UNCONFIRMED_REVIEW_FROM_NON_COMPANY_EMAIL_INFO', { ns: 'questionnaire' })}
            as={'span'}
          />{' '}
          <HtmlParser
            htmlString={t('UNCONFIRMED_REVIEW_CONFIRMATION_EMAIL_INFO', { ns: 'questionnaire' })}
            as={'span'}
          />
        </p>
        <div className={classes.Introduction__review}>
          <div className={classes.Introduction__reviewBlock}>
            <CommentsGroup>
              <Comment text={t('INTRODUCTION_REVIEW_POSITIVE', { ns: 'front' })} variant={'plus'}></Comment>
              <Comment text={t('INTRODUCTION_REVIEW_NEGATIVE', { ns: 'front' })} variant={'minus'}></Comment>
            </CommentsGroup>
          </div>

          <div className={classNames(classes.Introduction__reviewBlock, classes.Introduction__whatIsReview)}>
            <h3 className="typography-heading-small-text">{t('INTRODUCTION_COMMENT_TITLE', { ns: 'front' })}</h3>
            <p>{t('INTRODUCTION_COMMENT_CONTENT', { ns: 'front' })}</p>
          </div>
        </div>
        <h2 className="typography-heading-medium-text mt-1100">{t('INTRODUCTION_SECOND_SUBTITLE')}</h2>
        <p>
          <HtmlParser htmlString={t('INTRODUCTION_EIGHTH_PARAGRAPH')} as={'span'} />
        </p>
        <List listIconType={'tick'}>
          <li className="mb-700">
            <HtmlParser htmlString={t('INTRODUCTION_TICK_LIST_FIRST_ITEM')} as={'span'} />
          </li>
          <li className="mb-700">
            <HtmlParser htmlString={t('INTRODUCTION_TICK_LIST_SECOND_ITEM')} as={'span'} />
          </li>
          <li>
            <HtmlParser htmlString={t('INTRODUCTION_TICK_LIST_THIRD_ITEM')} as={'span'} />
          </li>
        </List>
        <p>
          <HtmlParser htmlString={t('INTRODUCTION_NINTH_PARAGRAPH')} as={'span'} />
        </p>
        <h2 className="typography-heading-medium-text mt-1100">
          <HtmlParser htmlString={t('INTRODUCTION_THIRD_SUBTITLE')} as={'span'} />
        </h2>
        <p>
          <HtmlParser
            htmlString={t('INTRODUCTION_TENTH_PARAGRAPH', { contactEmail: customerCareEmail, codexUrl })}
            as={'span'}
          />
        </p>
        <h2 className="typography-heading-medium-text mt-1100 tect-a">
          <HtmlParser htmlString={t('INTRODUCTION_FOURTH_SUBTITLE')} as={'span'} />
        </h2>
        <p className="typography-body-medium-text-bold">
          <HtmlParser htmlString={t('INTRODUCTION_ELEVENTH_PARAGRAPH')} as={'span'} />
        </p>
        <p>
          <HtmlParser htmlString={t('INTRODUCTION_TWELFTH_PARAGRAPH')} as={'span'} />
        </p>
        <div className={classes.Introduction__whatIsReview}>
          <h3 className="typography-heading-small-text text-center">
            {t('ATMOSKOP_IS_PART_OF_LMC_FAMILY', { siteName: getSiteName() })}
          </h3>
          <div className={classes.Introduction__brandPromo}>
            <a href="https://www.jobs.cz" target="_blank" className={classes.Introduction__jobPortalLogo}>
              <LogoJobs />
            </a>
            <a href="https://www.prace.cz" target="_blank" className={classes.Introduction__jobPortalLogo}>
              <LogoPrace />
            </a>
          </div>
        </div>
        <h2 className="typography-heading-medium-text mt-1100">
          <HtmlParser htmlString={t('INTRODUCTION_FIFTH_SUBTITLE')} as={'span'} />
        </h2>
        <p>
          <HtmlParser
            htmlString={t('INTRODUCTION_THIRTIETH_PARAGRAPH', {
              questionnairesCount: formatNumber(homepageStatistics?.homepageStatistics.questionnairesCount ?? 0),
            })}
            as={'span'}
          />
        </p>
        <p>
          <HtmlParser htmlString={t('INTRODUCTION_FOURTEENTH_PARAGRAPH')} as={'span'} />
        </p>
        <h2 className="typography-heading-medium-text mt-1100">
          <HtmlParser htmlString={t('INTRODUCTION_SIXTH_SUBTITLE')} as={'span'} />
        </h2>
        <p>
          <HtmlParser htmlString={t('INTRODUCTION_IT_ONLY_TAKES_FEW_MINUTES')} as={'span'} />
        </p>
        <p>
          <HtmlParser htmlString={t('INTRODUCTION_YOUR_GOOD_FRIEND')} as={'span'} />
        </p>
        <NextButtonLink
          href={generateRoute('front.questionnaire.basic', {
            zdroj: 'uvod',
            typ: 'atmoskop-web',
            uvodniObrazovka: '0',
          })}
          rel="nofollow"
          className="mb-700"
        >
          {t('INTRODUCTION_ADD_RAITING_BUTTON')}
        </NextButtonLink>
      </Container>
    </main>
  );
};

export default Introduction;
