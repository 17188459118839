import classNames from 'classnames';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import React from 'react';
import classes from '@components/utilities/SuitableCard/SuitableCard.module.scss';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  imageUrl: string;
  title: string;
  content: string;
  suitableProfilesCount: number;
  link: string;
}

const SuitableCard = ({ imageUrl, title, content, suitableProfilesCount, link, className, ...divProps }: Props) => {
  const { t } = useTranslation(['front']);

  return (
    <div className={classNames(classes.SuitableCard, className)} {...divProps}>
      <a href={link} className={classes.SuitableCard__link}>
        <div className={classes.SuitableCard__imageWrapper}>
          <Image src={imageUrl} alt="Image" fill className={classes.SuitableCard__image} />
        </div>
        <div className={classes.SuitableCard__body}>
          <h2 className={classes.SuitableCard__heading}>{title}</h2>
          <p className={classes.SuitableCard__content}>{content}</p>
          <p className={classes.SuitableCard__footer}>{t('COUNT_OF_COMPANIES', { count: suitableProfilesCount })}</p>
        </div>
      </a>
    </div>
  );
};

export default SuitableCard;
